module.exports={
    "ar_AE": {
        "decimal": ".",
        "thousands": ","
    },
    "bg_BG": {
        "decimal": ".",
        "thousands": ","
    },
    "cs_CZ": {
        "decimal": ",",
        "thousands": " "
    },
    "de_DE": {
        "decimal": ",",
        "thousands": " "
    },
    "en_US": {
        "decimal": ".",
        "thousands": ","
    },
    "es_ES": {
        "decimal": ",",
        "thousands": "."
    },
    "et_EE": {
        "decimal": ",",
        "thousands": " "
    },
    "fi_FI": {
        "decimal": ",",
        "thousands": " "
    },
    "fr_FR": {
        "decimal": ",",
        "thousands": " "
    },
    "he_IL": {
        "decimal": ",",
        "thousands": " "
    },
    "hu_HU": {
        "decimal": ",",
        "thousands": " "
    },
    "it_IT": {
        "decimal": ",",
        "thousands": "."
    },
    "ja_JP": {
        "decimal": ".",
        "thousands": ","
    },
    "ko_KR": {
        "decimal": ".",
        "thousands": ","
    },
    "nl_NL": {
        "decimal": ",",
        "thousands": "."
    },
    "pl_PL": {
        "decimal": ".",
        "thousands": " "
    },
    "pt_PT": {
        "decimal": ",",
        "thousands": " "
    },
    "ro_RO": {
        "decimal": ",",
        "thousands": "."
    },
    "ru_RU": {
        "decimal": ",",
        "thousands": " "
    },
    "sk_SK": {
        "decimal": ",",
        "thousands": " "
    },
    "th_TH": {
        "decimal": ".",
        "thousands": ","
    },
    "tr_TR": {
        "decimal": ",",
        "thousands": "."
    },
    "vi_VN": {
        "decimal": ",",
        "thousands": "."
    },
    "zh_TW": {
        "decimal": ",",
        "thousands": "."
    },
    "zh_CN": {
        "decimal": ".",
        "thousands": ","
    }
}